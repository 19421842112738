// local
// const BASE_URL = "https://192.168.21.19:5077/node_api/";
const BASE_URL = "https://backoffice.netflowpro.com/node_api/";

// const BASE_URL = "http://localhost:5000/node_api/";
const DEFAULT_KEY = '719df1fa-5df1-4f4b-a4c8-0ae3947ca368'
// const DEFAULT_KEY = '52PJ7KAbqFAfjA1PK9iHQ5dIsU8Ktfzj'
const PAYPAL_CLIENT_ID = "Ad077-Gx8st GcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

//live
// const BASE_URL = "https://backoffice.netflowpro.com/node_api/";
// const DEFAULT_KEY = '719df1fa-5df1-4f4b-a4c8-0ae3947ca368'
// const PAYPAL_CLIENT_ID = "Ad077-Gx8st GcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"
export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID } 